.tokenCard-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
}

.tokenCard-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.tokenCard-title {
    display: flex;
    justify-content: space-between;
}

.tokenCard-title--button {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.tokenCard-title--button-text {
    color: var(--black-50);
}

.tokenCard-title--listButton {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tokenCard-text {
    max-width: 250px;
    width: 100%;
}

.tokenCard-disbledButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}