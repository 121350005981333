.multimedia-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.multimedia-text {
    color: var(--black);
}

.multimedia-row {
    display: flex;
    flex-direction: row;
    gap: 6px;
    flex-wrap: wrap;
}

.multimedia-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: var(--gray-3);
    overflow: hidden;
}

.multimedia-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
}

.multimedia-card--icon {
    width: 3rem;
    height: 3rem;
    object-fit: fill;
    cursor: pointer;
}

.modal-list-imagenes {
    /* display: flex; */
    column-gap: 9px;
    row-gap: 10px;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(auto-fill);
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 0.6rem;
}

.modal-imagenes {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 125px;
    background: rgba(0, 0, 0, 0.05);
    /* border-radius: 8px; */
    color: var(--gray-3);
    overflow: hidden;
}

.modal-imagenes-content {
    width: 7rem;
    height: 8rem;
    object-fit: fill;
}

.modal-documents-content {
    width: 5.5rem;
    height: 5.5rem;
    object-fit: fill;
}

.modal-documents {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 125px;
    background: rgba(0, 0, 0, 0.05);
    /* border-radius: 8px; */
    color: var(--gray-3);
    overflow: hidden;
    padding: 5px;
}

.modal-documents-text {
    max-width: 190px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.7rem;
}


/** Multimedia */
.messageTopBar-user_main--imageIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-1);
    background: var(--white-50);
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.messageTopBar-user_main--imageIcon img {
    display: flex;
}

.modal-notification-nothing {
    text-align: center;
}

@media (max-width: 1100px) {
    .multimedia-container {
        gap: 13px;
    }
    .multimedia-row {
        gap: 10px;
    }
}
@media (max-width: 768px) {
    .modal-list-imagenes {
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
    }
    .modal-imagenes {
        height: 13rem;
        width: auto;
    }
    .modal-imagenes-content {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

@media (max-width: 685px) {
    .modal-imagenes {
        height: 11rem;
    }
}

@media (max-width: 642px) {
    .modal-imagenes {
        height: 10rem;
        width: 8.9rem;
    }
}

@media (max-width: 637px) {
    .modal-list-imagenes {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
    }
    .modal-imagenes {
        height: 15rem;
        width: auto;
    }
}

@media (max-width: 592px) {
    .modal-imagenes {
        height: 13rem;
        width: auto;
    }
}

@media (max-width: 556px) {
    .modal-imagenes {
        height: 11rem;
        width: auto;
    }
}

@media (max-width: 534px) {
    .modal-imagenes {
        height: 10rem;
        width: auto;
    }
}

@media (max-width: 496px) {
    .modal-imagenes {
        height: 9.4rem;
        width: auto;
    }
}

@media (max-width: 450px) {
    .modal-imagenes {
        height: 9rem;
        width: auto;
    }
}

@media (max-width: 430px) {
    .multimedia__6 {
        display: none;
    }
}

@media (max-width: 427px) {
    .modal-imagenes {
        height: 8rem;
        width: auto;
    }
}

@media (max-width: 387px) {
    .modal-list-imagenes {
        gap: 0.4rem
    }
}