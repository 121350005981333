.statusDropdown {
  width: 100%;
  position: relative; 
  border-radius: 12px;
}

.statusDropdown__disabled {
  pointer-events: none;
}

.statusDropdown-list {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.statusDropdown-select__disabled {
  border: 1px solid rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	backdrop-filter: blur(40px);
	border-radius: 12px;
	background-color: transparent !important;
}

.statusDropdown-select {
  max-height: 30px;
  height: 100%;
  padding: 20px 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'Faktum';
}

.statusDropdown-select__click {
  background: var(--white-50);
  border: 1px solid var(--blue-2);
  padding: 19px 9px !important;
  border-radius: 12px 12px 0px 0px;
}

.statusDropdown-list {
  display: none;
}

.statusDropdown-show {
  display: block;
}

.statusDropdown-list__item {
  padding: 10px 20px;
  /* height: 20px; */
  display: flex;
  align-items: center;
  font-family: 'Faktum';
}

.statusDropdown-list__item-icon {
  gap: 10px;
  font-family: 'Faktum';
}

.statusDropdown__item-icon div img{
  width: 24px;
  height: 24px;
}

.statusDropdown-list__item:last-child {
  border-radius:  0px 0px 12px 12px;
}

.statusDropdown-list__item:hover {
  background: var(--black-05);
  backdrop-filter: blur(40px);
}

.statusDropdown-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 50%);
}

.statusDropdown-select .move-text {
  transform: translate(0, 50%);
  font-family: 'Faktum-Medium';
}

.statusDropdown__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.statusDropdown-title-icon {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(55px, 50%);
}

.move-text-icon {
  transform: translate(0px, 50%);
  font-family: 'Faktum-Medium';
}

.statusDropdown-no-display {
  display: none;
}

.statusDropdown-no-transform {
  transform: none;
}

.statusDropdown-error {
  color: var(--red);
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
