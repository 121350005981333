.pricesPage-container {
    padding: 30px 80px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: flex-start;
    gap: 40px;
}

.pricesPage-left {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.pricesPage-right {
    max-width: 840px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.pricesPage-downloadButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.pricesPage-token-tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pricesPage-tokens--tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.pricesPage-tokens-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

@media(max-width: 1024px) {
    .pricesPage-container {
        padding: 30px 20px;
        gap: 20px;
    }

    .pricesPage-left {
        max-width: 300px;
    }
}
