.modal-notificaciones-header {
    display: flex;
    flex-direction: row;
    grid-gap: 40px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px
}
.modal-notificationes-content {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 20px;
    padding-top: 10px;
}
.modal-notificationes-content::-webkit-scrollbar {
    /*overflow: hidden;*/
    width: 0px;
}

@media (max-width: 768px) { 
    .modal-notificationes .modal-notification--main {
        max-width: 100% !important;
    }
    .modal-notificationes .modal-notification--main .modal-notification-container {
        max-width: 100%;
        background: red;
    }
}

@media (max-width: 768px) and (max-height: 1522px) { 
    .modal-notificationes-content {
        max-height: calc(100vh - 145px);
    }
}

@media (max-width: 400px) and (max-height: 1522px) { 
    .modal-notificationes-content {
        max-height: calc(100vh - 130px);
    }
}

@media (max-width: 400px) { 
    .modal-notificationes-content {
        width: 100%;
    }
}

/* 
@media (max-width: 768px) and (max-height: 1439px) { 
    .modal-notificationes-content {
        max-height: calc(100vh - 192px);
    }
} */