/*Estilo del header*/
.itemPersonalChat-card {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 87px;
    padding: 0px 20px 0px 10px;
    box-sizing: border-box;
    cursor: pointer;
}
.itemPersonalChat-card-active  {
    background: #F3F3F3;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 87px;
    padding: 0px 20px 0px 10px;
    box-sizing: border-box;
    cursor: pointer;
}
/*Hover (efecto al pasar el mouse)*/
.itemPersonalChat-card:hover {
    background: var(--white);
}
.itemPersonalChat-user {
	display: flex;
	align-items: center;
	gap: 10px;
}
.itemPersonalChat-user .useractive {
    width: 45px;
    height: 45px;
}
.itemPersonalChat-user .useractive-white {
    width: 45px;
    height: 45px;
}
.itemPersonalChat-user .useractive-white img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}
.itemPersonalChat-user .useractive img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}
.itemPersonalChat-content-header {
    justify-content: center;
    flex-direction: column;
    display: flex;
    max-width: 100%;
    width: 100%;
    gap: 5px;
    /* align-items: center; */
    align-self: center;
    height: 87px;
}
.itemPersonalChat-header {
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    height: 20px;
}
.itemPersonalChat-title-personal {
    max-width: 190px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black-75)
}
.itemPersonalChat-title-personal-hover{
    max-width: 170px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black-75)
}
.itemPersonalChat-header-oclock {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.itemPersonalChat-header-subcontent {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 22px;
}
.itemPersonalChat-header-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.itemPersonalChat-clock {
    color: var(--black-50);
}
.itemPersonalChat-subtitle-personal {
    color: var(--black-50);
    max-width: 202px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.itemPersonalChat-subtitle-personal-hover {
    color: var(--black-50);
    max-width: 179px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.itemPersonalChat-number {
    display: flex;
    align-items: center;
    gap: 10px
}

.itemPersonalChat-user .useractive .useractive-text {
    font-size: 18px;
}

.itemPersonalChat-user .useractive-white .useractive-logged {
    top: 75%;
}

@media (max-width: 1223px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 150px;
    }
}
@media (max-width: 1210px) { 
    .itemPersonalChat-title-personal {
        max-width: 159px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 140px;
    }
}
@media (max-width: 1193px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 130px;
    }
}
@media (max-width: 1187px) { 
    .itemPersonalChat-title-personal {
        max-width: 145px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 140px;
    }
}
@media (max-width: 1183px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 110px;
    }
}
@media (max-width: 1155px) { 
    .itemPersonalChat-title-personal {
        max-width: 190px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 185px;
    }
}
@media (max-width: 1152px) { 
    /* .itemPersonalChat-content-header{
        max-width: 206px;
    } */
    .itemPersonalChat-header,
    .itemPersonalChat-header-subcontent {
        /* width: 206px; */
    }
    .itemPersonalChat-subtitle-personal {
        max-width: 120px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (max-width: 1097px) { 
    .itemPersonalChat-card,
    .itemPersonalChat-card-active {
        padding: 0px 15px 0px 10px;
    }
    .itemPersonalChat-header, 
    .itemPersonalChat-header-subcontent,
    .itemPersonalChat-content-header{
        /* width: 185px; */
    }
    .itemPersonalChat-header-row,
    .itemPersonalChat-number {
        gap: 5px;
    }
    .itemPersonalChat-subtitle-personal {
        max-width: 103px;
    }
    .itemPersonalChat-title-personal  {
        /* max-width: 120px; */
    }
}
@media (max-width: 1008px) { 
    .itemPersonalChat-card,
    .itemPersonalChat-card-active {
        padding: 0px 14px 0px 7px;
        gap: 8px
    }
    .itemPersonalChat-header, 
    .itemPersonalChat-header-subcontent,
    .itemPersonalChat-content-header{
        /* width: 161px; */
    }
    .itemPersonalChat-header-row,
    .itemPersonalChat-number {
        gap: 5px;
    }
    .itemPersonalChat-title-personal {
        font-size: 0.9rem;
    }
    .itemPersonalChat-subtitle-personal {
        font-size: 0.8rem;
        max-width: 85px;
    }
}
@media (max-width: 926px) { 
    .itemPersonalChat-card,
    .itemPersonalChat-card-active {
        padding: 0px 12px 0px 5px;
    }
    .itemPersonalChat-header, 
    .itemPersonalChat-header-subcontent, 
    .itemPersonalChat-content-header {
        /* width: 145px; */
    }
    .itemPersonalChat-subtitle-personal {
        max-width: 68px;
    }
}
@media (max-width: 911px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 170px;
    }
}
@media (max-width: 893px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 155px;
    }
}

@media (max-width: 899px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 140px;
    }
}

@media (max-width: 890px) { 
    .itemPersonalChat-title-personal {
        max-width: 145px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 140px;
    }
}

@media (max-width: 860px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 127px;
    }
}


@media (max-width: 856px) { 
    .itemPersonalChat-title-personal {
        max-width: 135px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 120px;
    }
}

@media (max-width: 845px) { 
    .itemPersonalChat-title-personal {
        max-width: 190px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 185px;
    }
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 177px;
    }
}

@media (max-width: 835px) { 
    .itemPersonalChat-card,
    .itemPersonalChat-card-active {
        padding: 0px 12px;
    }
    .itemPersonalChat-header, 
    .itemPersonalChat-header-subcontent, 
    .itemPersonalChat-content-header {
        /* width: 145px; */
    }
    .itemPersonalChat-subtitle-personal {
        max-width: 68px;
    }
}

@media (max-width: 831px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 160px;
    }
}

@media (max-width: 817px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 150px;
    }
}

@media (max-width: 815px) { 
    .itemPersonalChat-title-personal {
        max-width: 170px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 150px;
    }
}

@media (max-width: 799px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 135px;
    }
}

@media (max-width: 790px) { 
    .itemPersonalChat-title-personal {
        max-width: 150px;
    }
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 128px;
    }
}

@media (max-width: 785px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 125px;
    }
}

@media (max-width: 768px) { 
    .itemPersonalChat-title-personal {
        max-width: 600px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 550px;
    }
    .chat-left .searchTextField-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 600px;
    }
}
@media (max-width: 725px) { 
    .itemPersonalChat-title-personal {
        max-width: 500px
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 450px;
    }
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 500px;
    }
}
@media (max-width: 770px) {
    .itemPersonalChat-title-personal-hover {
        max-width: 110px;
    }
}
@media (max-width: 768px) {
    .itemPersonalChat-title-personal-hover {
        max-width: 600px;
    }
}
@media (max-width: 736px) {
    .itemPersonalChat-title-personal-hover {
        max-width: 500px;
    }
}
@media (max-width: 646px) { 
    .itemPersonalChat-title-personal {
        max-width: 400px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 350px;
    }
}
@media (max-width: 623px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 400px;
    }
}
@media (max-width: 523px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 300px;
    }
}
@media (max-width: 505px) { 
    .itemPersonalChat-title-personal {
        max-width: 300px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 250px;
    }
}
@media (max-width: 522px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 250px;
    }
}
@media (max-width: 413px) { 
    .itemPersonalChat-title-personal {
        max-width: 250px;
    }
}
@media (max-width: 385px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 220px;
    }
}
@media (max-width: 376px) { 
    .itemPersonalChat-subtitle-personal-hover {
        max-width: 200px;
    }
}