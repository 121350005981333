.navbar-root  {
	width: 100%;
	height: 100%;
}

.navbar {
	position: relative;
	padding: 12px 40px 12px 20px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.603) 0%, rgba(255, 255, 255, 0) 133.32%, rgba(0, 0, 0, 0.335) 133.33%);
	backdrop-filter: blur(40px);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar-logo {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.navbar a {
	text-decoration: none;
}

.navbar-login-container {
	display: flex;
	align-items: center;
	gap: 39px;
}

.navbar-login {
	cursor: pointer;
}

.navbar-signup {
	cursor: pointer;
	background: #F7E246;
	border-radius: 24px;
	padding: 6px 30px;
}

.navbar-items {
	display: flex;
	align-items: center;
	gap: 40px;
}
.navbar-content--options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-options {
	display: flex;
	align-items: center;
	gap: 40px;
}

.navbar-content--row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.navbar-content-profile{
    display: flex;
    justify-content: start;
}

.navbar-content--button {
    max-width: 126px;
    width: 100%;
    max-height: 38px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
}

.navbar-content--row-profile {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
}
.navbar-content--row-circle {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(72.49deg, #ffa1416e -16.25%, #ffe34757 132.02%);
    border-radius: 50px;
}
.navbar-icons {
	display: flex;
	align-items: center;
	gap: 40px;
}

.navbar-icons div {
	cursor: pointer;
}

.navbar-mob--icon {
	display: flex;
	flex-direction: row;
	gap: 30px;
}

.navbar-user {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor:pointer
}

.navbar-mob-btn {
	display: none;
	max-width: 30px;
	width: 100%;
	max-height: 21px;
	height: 100%;
}

.navbar-content {
	position: absolute;
    width: 100%;
	height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--white);
	z-index: 1000;
}

.navbar-content--row {
	display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.navbar-content-profile .useractive img {
	width: 40px;
	height: 40px;
}

.navbar-content--user-options {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.navbar-content--userName {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.navbar-content--userName-userLastName {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.navbar-content--items {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.navbar-content--items-text {
	color: #394676;
}

.navbar-title-container {
	cursor: pointer;
}

@media (max-width: 1150px) {
    .navbar {
        padding: 12px 20px;
    }

	.navbar-items, .navbar-options, .navbar-icons {
		gap: 15px;
	}
	
}

@media (max-width: 990px) {
	.navbar-items .subheader, .navbar-options .navbar-user .paragraph-header {
		font-size: 0.75rem;	
	}
}

@media (max-width: 850px) {

	.navbar {
        padding: 12px 10px;
    }

	.navbar-items, .navbar-options, .navbar-icons {
		gap: 10px;
	}
}
