.orderResume-tablet-title {
    padding: 17px 20px !important;
    background: var(--white);
 }

 .orderResume-tablet-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 17px 20px !important;
    align-items: center;
 }

 .rowGroup_transparent {
   background-color: initial;
 }

 .rowGroup_white {
   background-color: var(--white-25);
 }