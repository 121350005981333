.filterSelectorMiniature-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 412px;
    padding: 7px 20px;
    gap: 40px;

    backdrop-filter: blur(40px);
    border-radius: 24px;
    background: var(--background);
    border: 1px solid;
    border-image-source: var(--border-image-source);
}

.filterSelectorMiniature-item {
    display: flex;
    flex-direction: row;
    gap: 10px;

    color: var(--gray-1);
}

.filterSelectorMiniature-item span {
    color: var(--gray-1);
}

@media (max-width: 430px) {
    .filterSelectorMiniature-main {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}