.profile {
    height: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
}

.profile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 840px;
}