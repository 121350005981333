.chat-page-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(97vh - 34px);
    overflow-x: hidden;
    overflow: hidden;
}

.chat-page-detail {
    width: 56.8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chat-page-detail-row {
    display: flex;
    flex-direction: row;
    gap: 20px
}

.chat-right {
    display: flex;
    flex-direction: column;
    gap: 20px
}