.resumeGeneral-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.resumeGeneral-row{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.resumeGeneral-row .searchTextField-container {
    max-width: 384px;
    width: 100%;
}

.resumeGeneral-row .custom-button-icon {
    box-shadow: none;
}

.resumeGeneral-cards {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
}

.resumeGeneral-control-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
 } 