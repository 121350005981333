.profile-detail {
    flex-direction: column;
    display: flex;
    width: 100%;
    max-width: 409px;
}
.profile-detail-content {
    flex-direction: row;
    align-items: center;
    display: flex;
    gap: 20px
}
.profile-detail-content
.useractive img {
	display: flex;
}
.profile-detail-content
.useractive img {
	width: 100px;
	height: 100px;
	border-radius: 70px;
}
.profile-detail-content
.useractive-logged {
	left: 80%;
    top: 80%;
    width: 14px;
	height: 14px;
}
.profile-detail-list {
    flex-direction: column;
    align-self: center;
    display: flex;
    gap: 5px;
    width: 219px;
}
.profile-detail-business, 
.profile-detail-nickname {
    color: var(--gray-2);
}
.profile-detail-name,
.profile-detail-business, 
.profile-detail-nickname {
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}

.profile-detail-icon {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
@media (max-width: 1184px) {
    .profile-detail {
        max-width: 324px;
    }
    .profile-detail-content
    .useractive img {
        width: 90px;
        height: 90px;
        border-radius: 70px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 77%;
        top: 86%;
        width: 14px;
        height: 14px;
    }
    .profile-detail-name {
        font-size: 1rem;
    }
    .profile-detail-business, 
    .profile-detail-nickname {
        font-size: 0.9rem;
    }
    .profile-detail-content {
        gap: 15px;
        max-width: 324px;
    }
    .profile-detail-list {
        gap: 1px
    }
}

@media (max-width: 986px) { 
    .profile-detail-content {
        gap: 8px
    }
    .profile-detail-list {
        width: 180px;
    }
}

@media (max-width: 766px) { 
    
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname {
        max-width: 400px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 480px) {
    .profile-detail-content {
        max-width: 409px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 77%;
        width: 14px;
        height: 14px;
    }
    .profile-detail-list {
        width: 100%;
    }
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname {
        max-width: 250px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 468px) { 
    .profile-detail-icon {
        gap: 20px
    }
}
@media (max-width: 425px) { 
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname {
        max-width: 220px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 375px) {
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 75%;    
    }
}
@media (max-width: 369px) { 
    .profile-detail-content {
        gap: 10px;
    }
    .profile-detail-content
    .useractive-logged {
        left: 83%;
        top: 79%;    
    }
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname {
        max-width: 200px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}
@media (max-width: 338px) { 
    .profile-detail-name,
    .profile-detail-business, 
    .profile-detail-nickname {
        max-width: 150px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
    }
}