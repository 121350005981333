.custom-button-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(40px);
	border-radius: 12px;
	border: none;
	color: var(--white);
	cursor: pointer;
}

.btnIcon-large {
	min-width: 190px;
	height: 43px;
	padding: 10px 50px;
}

.btnIcon-normal {
	min-width: 135px;
	height: 38px;
	padding: 10px 30px;
}

.btnIcon-small {
	min-width: 105px;
	height: 38px;
	padding: 10px 30px;
}

.btnIcon-extra-small {
	min-width: 103px;
	height: 26px;
	padding: 5px 15px;
}

.btnIcon-disabled {
	color: var(--gray-3) !important;
	box-sizing: border-box !important;
	box-shadow: none;
}

@media (max-width: 500px) { 
	.custom-button-icon {
		font-size: 10px;
		width: 60%;
		padding: 5px 10px;
		gap: 5px;
	}

 }

 @media (max-width: 400px) { 
	.custom-button-icon {
		font-size: 12px;
		width: auto;
		gap: 10px;

	}
 }
