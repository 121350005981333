.status-container-green {
    background: var(--green-2);
    border-radius: 24px;
    max-width: 100px;
}
.status-container-red {
    background: var(--red);
    border-radius: 24px;
    max-width: 142px;
}
.status {
    max-width: 109px;
    width: 100%;
    height: 30px;
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 10px;
}

.badge-text-color {
    color: var(--white);
}

@media (max-width: 1171px) {
    .status {
        height: 25px;
    }
}
@media (max-width: 375px) {
    .status-container-green { 
        margin-top: 4px;;
    }
}
@media (max-width: 337px) {
    .badge-text-color {
        font-size: 0.8rem;
    }
}