.metric-main {
    width: 100%;
    padding: 30px 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.metric-left {
    max-width: 400px;
    width: 100%;    
}

.metric-right {
    max-width: 840px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.metric-buttonEdit {
    display: flex; 
    flex-direction: row;
    gap: 20px;
}


@media (max-width: 1180px) {
    .metric-main {
        padding: 30px 40px;
        gap: 20px;
    }
}

@media (max-width: 1075px) {
    .metric-main {
        padding: 30px;
    }

    .metric-buttonTag {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

