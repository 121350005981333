.iconMessagBadget {
    display: flex;
    flex-direction: column;
    position: relative;
}
.iconMessagBadget-badget {
    position: absolute;
    background: var(--red);
    color: var(--white);
    border-radius: 24px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -0.6rem;
    left: 1rem;
    font-size: 1rem;
}
.iconMessagBadget-badget-100 {
    position: absolute;
    background: var(--red);
    color: var(--white);
    border-radius: 24px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -0.6rem;
    left: 1rem;
    font-size: 1rem;
    font-size: 0.65rem;
}