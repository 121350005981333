.list-button {
    gap: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.list-button 
.custom-button {
    color: var(--gray-1);
    box-shadow: none;
    text-align: left;
    padding: 10px 17px;
    border: none;
    border-radius: 12px;
    height: 40px;
    font-family: 'Faktum-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
}
.list-button-active {
    color: var(--white) !important;
}
