.orderResume-tablet-title {
    padding: 17px 20px !important;
    background: var(--white);
 }

 .orderResume-tablet-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 13px 20px !important;
    align-items: center;
 }

 .rowGroup_collapse--pointer {
    align-items: center;
    display: flex;
 }

 .rowGroup_collapse {
  background-color: var(--white-50);
 }

 .rowGroup_transparent {
   background-color: initial;
 }

 .rowGroup_white {
   background-color: var(--white-25);
 }

 .orderResume-tablet-footer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
 }