.groupShowDataButton-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.groupShowDataButton-search {
    width: 36px;
    height: 36px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: var(--background);
    border-image-source: var(--border-image-source);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 48px;   
}

.groupShowDataButton-search div {
    display: flex;
    align-self: center;
}

.groupShowDataButton-table {
    width: 36px;
    height: 36px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;   

    background: rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.groupShowDataButton-table div {
    display: flex;
    align-self: center;
}

.groupShowDataButton-chart {
    width: 36px;
    height: 36px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;   

    background: rgba(0, 0, 0, 0.25);
    border-radius: 24px;
}

.groupShowDataButton-chart div {
    display: flex;
    align-self: center;
}

.groupShowDataButton-active {
    background: rgba(0, 0, 0, 0.75);
}