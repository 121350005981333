.orderResume-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.orderResume-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.orderResume-tabs {
    cursor: pointer;
    background: transparent;
    border-radius:  24px;
    padding: 3px 15px;
    min-width: 69px;
}

.orderResume-tabs-background {
    background: var(--black-75);
    color: var(--white);
    border-radius:  24px;
    padding: 3px 15px;
    min-width: 69px;
}

.orderResume-control-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
 } 

 .order-control--dropdown {
    max-width: 420px;
 }


 .orderResume-tablet {
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    overflow: hidden;
 }