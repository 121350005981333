.accountingPage-container {
    padding: 30px 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

.accountingPage-container .humaResource-left,
.accountingPage-left {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.accountingPage-rigth {
    max-width: 840px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;    
    gap: 20px;
}

.accountingPage-ingressTabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px
}

.accountingPage-buttonsTag {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accountingPage-control-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.accountingPage-category {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.accountingPage-category .dropdown {
    min-width: 330px;
    width: fit-content;
}

.accountingPage-buttonDownload {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}