.couponModal-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.couponModal-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.couponModal-discount {
    max-width: 180px;
    width: 100%;
}

.couponModal-text-full {
    width: 100%;
}

.couponModal-text-middle {
    width: 50%;
}

.couponModal-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.couponModal-buttons div {
    display: flex;
}

.couponModal-downloadButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.couponModal--editButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.couponModal-editIcon img {
    width: 15px !important;
    height: 15px !important;
    display: flex;
}

.couponModal-row .couponModal-text-middle {
    width: 100%;
}

.couponModal-row .textfield .textfield-input {
    width: 100%;
    max-width: 100%;
}

.textfield .textfield-input {
    width: 100%;
    max-width: 100%;
}

.couponModal-content-search {
    background: var(--white);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    width: 400px;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    z-index: 500;
    position: absolute;
    margin-left: 1rem;
    padding: 20px;
    margin-top: -1px;
}

.couponModal-content-search .profile-detail {
    cursor: pointer;
}

.couponModal-content-search__ {
    height: 80px;
    background: var(--white);
    border-radius: 12px;
    width: 400px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 500;
    position: absolute;
}

.couponModal-content-search::-webkit-scrollbar {
    width: 1px;
    display: none;
}

.couponModal-content-search .profile-detail .profile-detail-content .useractive img {
    width: 40px;
    height: 40px;
}

.couponModal-content-search .profile-detail .profile-detail-content .useractive span {
    top: 75%;
    width: 9px;
    height: 9px;
}

.couponModal-content-search .profile-detail .profile-detail-content .profile-detail-list .profile-detail-name {
    font-size: 0.9rem;
    font-family: 'Faktum-Medium';
}

.couponModal-content-search .profile-detail .profile-detail-content .profile-detail-list .profile-detail-nickname {
    display: none;
}

