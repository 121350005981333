.chat-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    max-width: 350px;
    min-width: 180px;
}

.chat-left .searchTextField-container {
    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
    border-image-source: var(--border-image-source);
}

.chat-page-list {
    width: 100%;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    /*display: flex;*/
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

/*Scroll x */
.chat-page-list::-webkit-scrollbar,
.chat-page-options::-webkit-scrollbar {
    width: 0px;  
}

.chat-search {
    position: relative;
    display: flex;
    flex-direction: column;
}

.chat-content-search {
    position: absolute;
    top: 4rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    border: 1px solid;
    border-image-source: var(--border-image-source);
    overflow: hidden;
    background: var(--background);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 350px;
    max-height: 300px;
    min-height: 53px;
}

.chat-content-list-search {
    overflow: scroll;
}

.chat-content-list-search::-webkit-scrollbar {
    width: 0px;
}

.chat-content-search-item {
    padding: 15px;
    cursor: pointer;
}

.chat-content-search-item:hover {
    background: var(--gray-5);
}

.chat-content-search-item-nothing {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 834px) {
    .chat-page-list .itemPersonalChat-card{
        padding: 0px 12px 0px 6px;
    }
    .chat-page-list .itemPersonalChat-card
    .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent
    .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 112px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 180px;
    }
}

@media (max-width: 822px) {
    .itemPersonalChat-title-personal-hover {
        max-width: 165px;
    }
}

@media (max-width: 810px) {
    .itemPersonalChat-title-personal {
        max-width: 170px;
    }
}

@media (max-width: 808px) {
    .chat-page-list {
        /* max-width: 540px; */
        max-width: 100%;
    }
}

@media (max-width: 804px) {
    .itemPersonalChat-title-personal-hover {
        max-width: 150px;
    }
}

@media (max-width: 794px) {
    .itemPersonalChat-title-personal {
        max-width: 150px;
    }
}

@media (max-width: 792px) {
    .chat-page-list .itemPersonalChat-card 
    .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent 
    .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 87px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 130px;
    }
}

@media (max-width: 772px) { 
    /* .chat-page-list {
        max-width: 210px;
    } */
    .chat-page-list .itemPersonalChat-card 
    .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent 
    .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 100px;
    }
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 180px;
    }
    .itemPersonalChat-title-personal {
        max-width: 133px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 120px;
    }
}

@media (max-width: 768px) {
    .chat-left {
        min-width: 100%;
    }
    .chat-page-list .itemPersonalChat-card{
        padding: 0px 12px;
    }
    .chat-page-list {
        border-radius: 0px;
        margin-bottom: 3.7rem;
    }
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 550px;
    }
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 500px;
    }
}

@media (max-width: 763px) { 
    .itemPersonalChat-title-personal {
        max-width: 500px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 500px;
    }
    /* .chat-page-list .itemPersonalChat-card 
    .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent 
    .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 90px;
    } */
    /* .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 170px;
    } */
    /* .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 150px;
    } */
}

/* @media (max-width: 741px) { 
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 150px;
    }
} */

@media (max-width: 728px) { 
    .chat-page-list .itemPersonalChat-card {
        justify-content: flex-start;
        gap: 10px
    }
    /* .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 200px;
    } */
}

@media (max-width: 689px) {
    .chat-page-list .itemPersonalChat-card {
        justify-content: flex-start;
    }
    
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header {
        width: 100%;
        max-width: 100%;
    }

    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header {
        width: 100%;
        max-width: 100%;
    }
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent {
        width: 100%;
        max-width: 100%;
    }
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent .itemPersonalChat-header-row .itemPersonalChat-subtitle-personal {
        width: 100%;
        max-width: 450px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-title {
        width: 100%;
        max-width: 470px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-business {
        width: 100%;
        max-width: 470px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-group-chat .itemGroupChat-card-textColor-msg {
        width: 100%;
        max-width: 470px;
    }
}

@media (max-width: 600px) {
    .chat-left .searchTextField-container {
        margin-left: 2rem;
        margin-right: 2rem;
        width: 100%;
        max-width: 90%;
    }
    .chat-page-list .itemPersonalChat-card {
        padding: 0px 12px;
    }
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent 
    .itemPersonalChat-header-row .itemPersonalChat-subtitle-personal {
        max-width: 380px;
    }
    .chat-page-list {
        margin-bottom: 3rem;
    }
}

@media (max-width: 551px) {
    .chat-left .searchTextField-container {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 92%;
    }
    .chat-page-list {
        border-radius: 0px;
        /* max-width: 540px; */
        max-width: 100%;
    }
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-title,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-card-textColor-business,
    .chat-page-list .itemGroupChat-card-container
    .itemGroupChat-card-body .itemGroupChat-group-chat
    .itemGroupChat-card-textColor-msg {
        max-width: 345px;
    }
}

@media (max-width: 534px) {
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 300px;
    }
}

@media (max-width: 525px) { 
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent .itemPersonalChat-header-row .itemPersonalChat-subtitle-personal {
        width: 100%;
        max-width: 300px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-title {
        width: 100%;
        max-width: 370px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-business {
        width: 100%;
        max-width: 370px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-group-chat .itemGroupChat-card-textColor-msg {
        width: 100%;
        max-width: 370px;
    }
}


@media (max-width: 460px) { 
    .itemPersonalChat-title-personal {
        max-width: 290px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 300px;
    }
}

@media (max-width: 445px) {
    .chat-page-list .itemPersonalChat-card 
    .itemPersonalChat-content-header 
    .itemPersonalChat-header-subcontent 
    .itemPersonalChat-header-row 
    .itemPersonalChat-subtitle-personal {
        max-width: 250px;
    }
} 

@media (max-width: 436px) { 
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent .itemPersonalChat-header-row .itemPersonalChat-subtitle-personal {
        width: 100%;
        max-width: 250px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-title {
        width: 100%;
        max-width: 315px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-business {
        width: 100%;
        max-width: 315px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-group-chat .itemGroupChat-card-textColor-msg {
        width: 100%;
        max-width: 315px;
    }
}

@media (max-width: 412px) { 
    .itemPersonalChat-title-personal {
        max-width: 260px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 260px;
    }
}

@media (max-width: 392px) { 
    .itemPersonalChat-title-personal {
        max-width: 355px;
    }
    .itemPersonalChat-title-personal-hover {
        max-width: 250px;
    }
}

@media (max-width: 384px) { 
    .chat-page-list .itemPersonalChat-card .itemPersonalChat-content-header .itemPersonalChat-header-subcontent .itemPersonalChat-header-row .itemPersonalChat-subtitle-personal {
        width: 100%;
        max-width: 200px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-title {
        width: 100%;
        max-width: 265px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-card-textColor-business {
        width: 100%;
        max-width: 265px;
    }
    .chat-page-list .itemGroupChat-card-container .itemGroupChat-card-body .itemGroupChat-group-chat .itemGroupChat-card-textColor-msg {
        width: 100%;
        max-width: 265px;
    }
}

@media (max-width: 375px) { 
    .itemPersonalChat-title-personal-hover {
        max-width: 230px;
    }
    .itemPersonalChat-title-personal {
        max-width: 235px;
    }
}

@media (max-width: 351px) { 
    .itemPersonalChat-title-personal-hover,
    .itemPersonalChat-title-personal {
        max-width: 225px;
    }
}