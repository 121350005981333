.accountTable-containet {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 12px;
}

.accountTable-row {
    width: 100%;
    display: grid;
    justify-content: flex-start;
    gap: 10px;
}

.accountTable-header {
    padding: 20px;
    background-color: var(--white-90);
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.accountTable-content-header {
    max-width: 280px;
    min-width: 100px;
    width: 100%;
    display: flex;
}

.accountTable-body {
    padding: 17px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.accountTable-content-body {
    max-width: 280px;
    width: 100%;
    min-width: 100px;
    display: flex;
}

.accountTable-content-body div {
    max-width: 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.accountTable-cell {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.buttonTable {
    max-width: 135px;
    overflow: hidden;
    white-space: nowrap;
}

.accountTable-footer {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--white-90);
    border-radius: 0px 0px 12px 12px;

}

@media (max-width: 1024px) { 
    .accountTable-header, .accountTable-body  {
        padding: 15px 10px;
    }

    .accountTable-row { 
        gap: 5px;
    }
}

@media (max-width: 875px) { 
    .accountTable-content-header {
        font-size: 0.9rem;
    }
    .accountTable-content-body div {
        font-size: 0.85rem;
    }
}