.logIn-container {
    max-width: 29.438rem;
    padding: 0px 20px   ;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.logIn-divider {
    position: static;
    width: 300px;
    height: 2px;
    background-color: #C4C4C4;
    border: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.logIn-remember {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logIn-checkbox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.footer-login {
    text-align: center;
    font-size: 1rem;
}

.logIn-container .textfield  .textfield-input {
    max-width: 100%;
}

@media (max-width: 500px) { 
    .logIn-container .custom-button-icon, 
    .logIn-container .btn-large {
        font-size: 0.9rem;
    }
    .logIn-remember-text{
        font-size: 0.8rem;
    }
    .footer-login {
        font-size: 0.8rem;
    } 
}
@media (max-width: 470px) {
    .btnIcon-large {
        padding: 10px;
    }
    .logIn-container {
        padding: 0px;
    }
}

@media (max-width: 338px) {
    .logIn-divider {
        width: 270px;
    }
}

@media (max-width: 327px) {
    .logIn-remember .logIn-checkbox .checkbox-container,
    .checkbox-container input[type="checkbox"] {
        width: 28px;
        height: 28px;
    }
    .logIn-checkbox {
        gap: 3px;
    }
}

@media (max-width: 305px) {
    .logIn-divider {
        width: 200px;
    }
}