.chat-container,
.chat-personal {
    position: relative;
    /* height: 100%; */
    width: 100%;
    max-width: 52.5rem;
    padding: 10px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    background: var(--background),
    linear-gradient(110.97deg, 
    rgba(255, 255, 255, 0.5) -4.87%, 
    rgba(255, 255, 255, 0) 103.95%);
    border-image-source: var(--border-image-source);
    padding: 20px;
    height: calc(100vh - 181px);
}
/* chat normal**/
.chat-all {
    position: relative;
    /* height: 100%; */
    width: 100%;
    max-width: 52.5rem;
    padding: 10px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(40px);
    border-radius: 24px;
    background: var(--background),
    linear-gradient(110.97deg, 
    rgba(255, 255, 255, 0.5) -4.87%, 
    rgba(255, 255, 255, 0) 103.95%);
    border-image-source: var(--border-image-source);
    padding: 20px;
    height: 88.8vh;
}
.chat-all .chat-message {
    justify-content: center;
}
.chat-message {
    display: flex;
    max-height: calc(81vh - 8px);
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    flex-direction: column;
    justify-content: end;
}
.chat-message::-webkit-scrollbar {
    width: 0px;
    -webkit-appearance: none;
}
.chat-list-message {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 100%;
    width: 100%;
    overflow: hidden; 
    overflow-y: scroll; 
    /* justify-content: end;
    align-items: center; */
    /* margin-bottom: 1rem; */
}
.chat-list-message::-webkit-scrollbar {
    width: 0px;
}
.chat-nothing {
    height: 80%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.scroll-up .chat-container .time-chat-container-relative,
.scroll-up .chat-personal .time-chat-container-relative {
    transform: none;
}

.animation-in {
    animation-duration: 1s;
    animation-name: global-animation;
    animation-fill-mode: forwards;
}
.animated {
    opacity: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
@keyframes global-animation {
    0% {
      opacity: 0;
      transform: translateY(32px) perspective(300px);
    }
    100% {
      opacity: 1;
      transform: translateY(0) perspective(0);
    }
}

@media (max-height: 876px) {
    .chat-all {
        height: 88.4vh;
    }
}

@media (max-height: 837px) {
    .chat-all {
        height: 88.2vh;
    }
}

@media (max-height: 810px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 178px);
    }
}

@media (max-height: 804px) {
    .chat-all {
        height: calc(100vh - 98px);
    }
}

@media (max-height: 737px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 176px);
    }
}

@media (max-height: 670px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 175px);
    }
}

@media (max-height: 634px) {
    .chat-all {
        height: calc(100vh - 93px);
    }
}

@media (max-height: 627px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 173px);
    }
}

@media (max-height: 519px) {
    .chat-all {
        height: calc(100vh - 90px);
    }
}

@media (max-width: 600px) {
    .chat-container, .chat-personal {
        padding: 0px 5px 24px 5px;
    }
}

/* @media (max-height: 580px) {
    .chat-message {
        max-height: calc(100vh - 254px);
        min-width: 10vh;
    }
} */

@media (max-height: 564px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 171px);
    }
}

@media (max-height: 489px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 169px);
    }
}

@media (max-height: 439px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 167px);
    }
}

@media (max-height: 396px) {
    .chat-all {
        height: calc(100vh - 85px);
    }
}

@media (max-height: 362px) {
    .chat-container, .chat-personal {
        height: calc(100vh - 165px);
    }
}

@media (max-width: 425px) {
    /* .chat-list-message {
        height: 630px;
    } */
}

