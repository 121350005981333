.coupongPage-container {
    /* max-width: 1280px; */
    width: 100%;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;  
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.coupongPage-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.coupongPage-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.coupongPage-filters {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.coupongPage-buttonFilter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.coupongPage-downloadButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.coupongPage-filters-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.coupongPage-container .coupongPage-buttonFilter {
    width: 70%;
}

.coupongPage-container .accountTable-containet {
    max-width: 100%;
}

.couponPage-btnAction {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.couponPage-btnAction .custom-button {
    box-shadow: 0px 0px 0px var(--black-15);
}

/* .coupongPage-container .modal-main .modal-container {
    max-width: 69rem;
} */

@media (max-width: 1300px) {
    .coupongPage-container {
        padding: 30px;
    }

}

@media (max-width: 1150px) {

    .coupongPage-container {
        padding: 30px 20px;
    }
}

@media (max-width: 1024px) {
    .coupongPage-container .accountTable-containet .accountTable-row .accountTable-cell .couponPage-btnAction { 
        display: flex;
        flex-direction: column;
    }

    .coupongPage-container .accountTable-header, .accountTable-body {
        padding: 8px 10px;
    }
}