.admin-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footer-bottom {
	padding-bottom: 60px;
}