.messageChat-main {
    display: flex;
}

.messageChat__left {
    justify-content: flex-start;
    display: flex;
    width: 100%;
}

.messageChat__rigth {
    justify-content: flex-end;
}

.messageChat-container {
    /* padding: 10px 0px 5px 0px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;  
    min-width: 30px;
    max-width: 80%;
}

.messageChat-box {
    padding: 10px 20px;

}

.messageChat__right {
    display: flex;
    width: 100%;
    justify-content: end;
}

.messageChat-box__left {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 0px 24px 24px 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
   
}

.messageChat-box__rigth {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 24px 24px 0px 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.messageChat-colorWhite {
    color: var(--white-90);
}

.messageChat-colorBlack {
    color: var(--black-75);
}

.messageChat-box__timeWhite {
    display: flex;
    justify-content: flex-end;
    /* padding-right: 20px; */
    color: var(--white-75);
}

.messageChat-box__timeBlack {
    display: flex;
    justify-content: flex-start;
    color: var(--black-50);
}

.messageChat-button {
   margin: 5px 0px;
}

.messageChat-alert {
    color: var(--red);
    text-align: center;
}

.messageChat-attachment {
    min-width: 189px;
    min-height: 111px;
    padding: 10px 10px 5px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    /* Blanco Transparencia/White 25% */
    background: rgba(255, 255, 255, 0.25);
    /* Negro Transparencia/Black 25% */
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.messageChat-attachment-black {
    min-width: 189px;
    min-height: 111px;
    padding: 10px 10px 5px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    /* Blanco Transparencia/White 25% */
    background: rgb(202 202 202 / 25%);
    /* Negro Transparencia/Black 25% */
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.messageChat-attachment--icon {
    width: 50px;
    height: 63px;
    object-fit: contain;
}

.messageChat-name-document {
    max-width: 490px;
    width: 100%;
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black-75);
}

.messageChat-name-documentWhite {
    max-width: 490px;
    width: 100%;
    min-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--white-90);
}

@media (max-width: 768px) {
    
    .messageChat-container .small-bold {
        font-size: 16px;
    }

    .messageChat-container .messageChat-box .paragraph {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .messageChat-button button  {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .messageChat-box__time {
        font-size: 10px;
    }

}

@media (max-width: 425px) {
    
    .messageChat-container .small-bold {
        font-size: 14px;
    }

    .messageChat-container .messageChat-box .paragraph {
        font-size: 12px;
    }

    .messageChat-container .messageChat-box .messageChat-button button  {
        font-size: 12px;
    }

    .messageChat-container .messageChat-box .messageChat-box__time {
        font-size: 8px;
    }

}