.chat-header {
    display: flex;
    flex-direction: column;
    gap: 14px;                                                                                                                                                                                                                                                                        
}
.chat-header-content {
    width: 100%;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    height: 60px;
    align-items: center;
    padding: 7.5px 19px 7.5px 0px;
    /* background: var(--white-50); */
    border-radius: 24px;
    justify-content: center; 

    background: linear-gradient(110.97deg, rgba(255, 255, 255, 0.5) -4.87%, rgba(255, 255, 255, 0) 103.95%);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.chat-header-content-personal {
    width: 100%;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    height: 60px;
    align-items: center;
    padding: 7.5px 19px 7.5px 0px;
    /* background: var(--white-50); */
    border-radius: 24px;
    justify-content: center; 

    background: var(--white-50);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}
.chat-header-content-personal .chat-header-right {
    width: 100%;
}
.chat-header-left {
	gap: 10px;
	width: 100%;
	height: 60px;
    display: flex;
    max-width: 380px;
    min-width: 250px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    flex-direction: row;
    background: var(--gray-1);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    clip-path: polygon(0 0, 100% 0%, 93% 100%, 0% 100%);
}
.chat-header-left-personal {
	gap: 10px;
	width: 100%;
	height: 60px;
    display: flex;
    max-width: 54rem;
    padding-right: 20px;
    align-items: center;
    flex-direction: row;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    clip-path: polygon(0 0, 100% 0%, 93% 100%, 0% 100%);
}
.chat-header-left-users-name {
    max-width: 350px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black-75);
}
.chat-header-left-users {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding-left: 0.5rem;
}
.chat-header-left-users .useractive img {
    width: 45px;
    height: 45px;
}

.chat-header-left-users .useractive-white {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));
}
.chat-header-left-users .useractive-white img {
    width: 45px;
    height: 45px;
    object-fit: contain;
}
.chat-header-left-users .useractive-white .useractive-logged,
.chat-header-left-users .useractive .useractive-logged {
    top: 75%;
}
.chat-header-left-users .useractive-white .useractive-text {
    font-size: 18px;
    color: var(--gray-4)
}
.chat-header-left-users .useractive {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
}
.chat-header-left-users .useractive .useractive-text {
    font-size: 18px;
}
.chat-header-back-icon {
    cursor: pointer;
}
.chat-header-text-right {
    max-width: 313px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.arkabia-op-icon, .chat-header-home,
.chat-more-options,
.chat-header-text-right {
	align-self: center;
}
.chat-more-options-personal {
    justify-content: end;
    align-self: center;
    display: flex;
    width: 100%;
}
.chat-header-title-content {
	flex-direction: column;
	display: flex;
    max-width: 295px;
    min-width: 150px;
    width: 100%;
}
.chat-header-title-order {
    max-width: 290px;
    min-width: 100px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-header-title-order, 
.chat-header-title-type-operation {
	margin: 0px;
    color: var(--white);
}
.chat-header-options {
	align-self: center;
	display: none;
}
.chat-header-right {
    width: 100%;
    height: 60px;
    display: flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-right-radius: 21px;
}
.chat-header-right-content {
    gap:20px;
    display: flex;
    padding-left: 20px;
    flex-direction: row;
}
.chat-header-right-dropdown {
    display: none;
}
.chat-header-right-dropdown .dropdown .dropdown-select .move-text {
    left: 0px;
}
/* .chat-header-mobile-options {
    display: none;
} */
@media (max-width: 1372px) {
    .chat-header-text-right {
        max-width: 280px;
        min-width: 149px;
    }
}
@media (max-width: 1345px) {
    .chat-header-text-right {
        max-width: 250px;
    }
}
@media (max-width: 1300px) { 
    .chat-header-left {
        max-width: 280px;
    }
    .chat-header-right-content {
        padding-left: 6px;
    }
}
@media (max-width: 1287px) {
    .chat-header-text-right {
        max-width: 240px;
    }
}
@media (max-width: 1248px) { 
    .chat-header-title-content {
        max-width: 200px;
        width: 100%;
    }
    .chat-header-text-right {
        max-width: 230px;
    }
    .chat-header-title-order,
    .chat-header-text-right {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (max-width: 1248px) {
    .chat-header-left { 
        padding-left: 10px;
    }
}
@media (max-width: 1184px) {
    .chat-header-left-users-name {
        max-width: 300px;
    }
}
@media (max-width: 1050px) {
    /* .chat-header-content-personal {
        width: 100%;
        max-width: 100%;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        height: 60px;
        align-items: center;
        background: var(--white-50);
        border-radius: 24px;
        justify-content: center; 
    } */
    .chat-header-left-users-name {
        max-width: 400px;
    }
}
@media (max-width: 1042px) {
    .chat-header-left {
        max-width: 205px;
    }
    .chat-header-title-content { 
        max-width: 140px;
    }
}
@media (max-width: 958px) { 
    .chat-header-right-content {
        gap: 10px;
        width: 169px;
    }
    .chat-header-title-order,
    .chat-header-title-type-operation,
    .chat-header-text-right {
        font-size: 0.9rem;
    }
}
@media (max-width: 880px) { 
    .chat-header-left {
        max-width: 190px;
    }
    .chat-header-title-content{
        max-width: 130px;
    } 
}
@media (max-width: 845px) {
    .chat-header-left-users-name {
        max-width: 300px;
    }
}
@media (max-width: 840px) { 
    .chat-header-left {
        max-width: 160px;
    }
    .chat-header-title-content{
        max-width: 130px;
    }
}
@media (max-width: 834px) { 
    .chat-header-content {
        background: var(--black-05);
        display: flex;
        flex-direction: column;
        padding: 0px;
        height: 110px;
    }
    .chat-header-left {
        max-width: 800px;
        width: 100%;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        border-bottom-left-radius: 0px;
        padding-left: 20px;
        justify-content: space-between;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
    .chat-header-right {
        height: 50px;
        box-sizing: border-box;
        padding: 0px 10px;
        width: 100%;
        max-width: 800px;
    }
    .chat-header-right-dropdown {
        display: flex;
    }
    .chat-header-options,
    /* .chat-header-mobile-options {
        display: block;
    } */
    .chat-header-options {
        padding-right: 10px
    }
    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content,
    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content
    .chat-header-right-dropdown {
        max-width: 800px;
        width: 100%;
    }
    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content
    .chat-header-right-dropdown .dropdown-select {
        padding: 0px;
        height: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 700px;
    }

    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content
    .chat-header-right-dropdown .dropdown{
        height: 35px;
    }
    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content
    .chat-header-right-dropdown .dropdown-select 
    .move-text { 
        transform: translate(0, 40%);
        font-size: 0.85rem;
    }
    .chat-page-detail .chat-header 
    .chat-header-right .chat-header-right-content {
        align-self: center;
    }
    .chat-header-text-right, .chat-more-options {
        display: none;
    }
    .chat-header-title-content {
        max-width: 430px;
    }
}
@media (max-width: 768px) { 
    .chat-header-left-personal {
        padding-left: 20px;
    }
}
@media (max-width: 729px) { 
    .chat-header-mobile-options 
    .messageTopBar-main {
        max-width: 30rem;
    }
    .chat-header-mobile-options 
    .messageTopBar-main .messageTopBar-users {
        gap: 5px;
    }
}
@media (max-width: 563px) { 
    .chat-header-mobile-options 
    .messageTopBar-main {
        max-width: 30rem;
    }
    .chat-header-mobile-options 
    .messageTopBar-main .messageTopBar-users,
    .chat-header-mobile-options 
    .messageTopBar-main .messageTopBar-users 
    .messageTopBar-user_main  {
        gap: 4px;
    }
}
@media (max-width: 444px) { 
    .chat-header-left-users-name {
        max-width: 263px;
    }
}
@media (max-width: 413px) { 
    .chat-header-left-users-name {
        max-width: 228px;
    }
}
@media (max-width: 373px) { 
    .chat-header-left-users-name {
        max-width: 200px;
    }
}