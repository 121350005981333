.newTokenCard-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
}

.newTokenCard-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.newTokenCard-text {
    max-width: 250px;
    width: 100%;
}  