.useractive {
	position: relative;
	background: var(--gray-1);
	width: 36px;
	height: 36px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.useractive-white {
	position: relative;
	background: var(--white);
	width: 90px;
	height: 90px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.useractive-text {
	font-size: 18px;
	color: var(--white);
}

.useractive img {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	object-fit: cover;
	aspect-ratio: 16 / 9;
}

.useractive-white img {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	object-fit: cover;
	aspect-ratio: 16 / 9;
}

.useractive-logged {
	/* width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: var(--green-2);
	position: absolute;
	left: 75%;
	right: 0%;
	top: 75%;
	bottom: 0%; */
	width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--green-2);
    position: absolute;
    left: 74%;
    right: 0%;
    top: 66%;
    bottom: 0%;
}

#photo-upload {
	display: none;
}