.time-chat-container {
    margin-top: 1rem;

    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    position: fixed;
    min-height: 12px;
    word-wrap: break-word;
    width: 93.5%;
}

.time-chat-container-relative {
    margin-top: 1rem;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    position: relative;
    min-height: 12px;
    word-wrap: break-word;
    width: 100%;
}


.time-chat-content {
    background: var(--white-90);
    border-radius: 24px;
    padding: 2px 15px;
    display: flex;
    position: absolute;
}

.time-chat-line {
    height: 3px;
    width: 100%;
    background: var(--white-50);
}