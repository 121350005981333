.summaryCard-container {
    width: auto;
    min-height: 150px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: var(--background);
    border-image-source: var(--border-image-source);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 12px;
}  

.summaryCard-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.summaryCard-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.summaryCard-item {
    max-width: 200px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.summaryCard-item div {
    display: flex;
    align-items: center;
}

.summaryCard-item-text div {
    display: flex;
    text-align: center;
}

.summaryCard-item-text {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
