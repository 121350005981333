.textfield {
	position: relative;
}

.textfield input {
	width: 100%;
	max-width: 160px;
	height: 50px;
	padding: 10px 12px;
	border-radius: 12px;
	box-sizing: border-box;
	outline: none;
	border: 1px solid var(--black-15);
	background-color: var(--white-90);
	text-overflow: ellipsis;
}

.textfield-title {
	pointer-events: none;
	position: absolute;
	left: 20px;
	top: 50%;
	color: var(--gray-3);
	transform: translateY(-50%);
	transition: 
	top 0.3s ease,
	font-size 0.3s ease,
	color 0.3s ease;
}

.textfield input:focus + .textfield-title {
	top: 15px;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--black);
}

.textfield input:focus::placeholder {
	opacity: 1;
	color: var(--gray-3);
	font-weight: 500;
	font-size: 1rem;
	line-height: 20px;
}

.textfield input::placeholder {
	/* opacity: 0; */
}

.textfield input:disabled {
	border: 1px solid rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	backdrop-filter: blur(40px);
	border-radius: 12px;
	background-color: transparent;
}

.textfield input:focus {
	padding: 22.5px 20px 7.5px 20px;
	border: 1px solid var(--blue-2);

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: var(--black);
}

.textfield input:focus ~ .textfield-title,
.textfield input:not(:placeholder-shown).textfield-input:not(:focus) ~ .textfield-title {
	top: 15px;
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 15px;
	color: var(--black);
}

.textfield input:not(:placeholder-shown).textfield-input:not(:focus) {
	padding: 22.5px 20px 7.5px 20px;

	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: var(--black);
}

.textfield-disabled {
	border: 1px solid var(--black-25) !important;
	background-color: transparent !important;
}

.textfield-disabled + span {
	color: var(--gray-3);
	font-weight: 500;
}

.textfield-error {
	width: fit-content;
	margin-left: 10px;
	color: var(--red);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}